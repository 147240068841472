const initialState = {
  isLoadingDescuento: false,
  errorDescuento: false,
  successDescuento: false,
  dataDescuento: false,
};
export const descuentoReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case "DESCUENTO_LOADING":
      return {
        ...state,
        isLoadingDescuento: true,
        errorDescuento: false,
        successDescuento: false,
        dataDescuento: false,
      };
    case "DESCUENTO_CHECK":
      return {
        ...state,
        isLoadingDescuento: false,
        successDescuento: payload.message,
        errorDescuento: false,
        dataDescuento: payload.data,
      };

    case "DESCUENTO_ERROR":
      return {
        ...state,
        isLoadingDescuento: false,
        errorDescuento: payload,
        successDescuento: false,
        dataDescuento: false,
      };
    case "DESCUENTO_RESET_MESSAGE":
      return {
        ...state,
        successDescuento: false,
        errorDescuento: false,
        dataDescuento: false,
      };
    case "DESCUENTO_FAKE_RESET":
      return {
        ...initialState,
      };
    case "DISCONNECT":
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
