const initialState = {
  isLoadingDorsal: false,
  errorDorsal: false,
  successDorsal: false,
  dataDorsal: false,
};
export const dorsalReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case "DORSAL_LOADING":
      return {
        ...state,
        isLoadingDorsal: true,
        errorDorsal: false,
        successDorsal: false,
      };
    case "DORSAL_CREATE":
      return {
        ...state,
        isLoadingDorsal: false,
        successDorsal: payload.message,
        errorDorsal: false,
      };
    case "DORSAL_ERROR":
      return {
        ...state,
        isLoadingDorsal: false,
        errorDorsal: payload,
        successDorsal: false,
      };
    case "DORSAL_RESET_MESSAGE":
      return {
        ...state,
        successDorsal: false,
        errorDorsal: false,
      };
    case "DORSAL_FAKE_RESET":
      return {
        ...initialState,
      };
    case "DISCONNECT":
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
