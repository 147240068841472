import { combineReducers } from "redux";
import { securityReducer } from "./security";
import { eventsReducer } from "./events";
import { inscriptionsReducer } from "./inscriptions";
import { backofficeReducer } from "./backoffice";
import { entradasReducer } from "./entradas";
import { stripeReducer } from "./stripe";
import { ordersReducer } from "./orders";
import { descuentoReducer } from "./descuento";
import { dorsalReducer } from "./dorsal";

//
export const rootReducer = combineReducers({
  security: securityReducer,
  events: eventsReducer,
  inscriptions: inscriptionsReducer,
  backoffice: backofficeReducer,
  entradas: entradasReducer,
  stripe: stripeReducer,
  orders: ordersReducer,
  descuento: descuentoReducer,
  dorsal: dorsalReducer,
});
