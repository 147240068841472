const initialState = {
  isLoadingEntradas: false,
  errorEntradas: false,
  successEntradas: false,
  dataEntradas: false,
};
export const entradasReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case "ENTRADAS_LOADING":
      return {
        ...state,
        isLoadingEntradas: true,
        errorEntradas: false,
        successEntradas: false,
      };
    case "ENTRADAS_CREATE":
      return {
        ...state,
        isLoadingEntradas: false,
        successEntradas: payload.message,
        errorEntradas: false,
      };
    case "ENTRADAS_FETCH_ALL":
      return {
        ...state,
        isLoadingEntradas: false,
        successEntradas: payload.message,
        errorEntradas: false,
        dataEntradas: payload.data,
      };
    case "ENTRADAS_ERROR":
      return {
        ...state,
        isLoadingEntradas: false,
        errorEntradas: payload,
        successEntradas: false,
      };
    case "ENTRADAS_RESET_MESSAGE":
      return {
        ...state,
        successEntradas: false,
        errorEntradas: false,
      };
    case "ENTRADAS_FAKE_RESET":
      return {
        ...initialState,
      };
    case "DISCONNECT":
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
