const initialState = {
  isLoadingOrders: false,
  errorOrders: false,
  successOrders: false,
  dataOrders: false,
};
export const ordersReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case "ORDERS_LOADING":
      return {
        ...state,
        isLoadingOrders: true,
        errorOrders: false,
        successOrders: false,
      };
    case "ORDERS_CREATE":
      return {
        ...state,
        isLoadingOrders: false,
        successOrders: payload.message,
        errorOrders: false,
      };
    case "ORDERS_FETCH_ALL":
      return {
        ...state,
        isLoadingOrders: false,
        successOrders: payload.message,
        errorOrders: false,
        dataOrders: payload.data,
      };

    case "ORDERS_ERROR":
      return {
        ...state,
        isLoadingOrders: false,
        errorOrders: payload,
        successOrders: false,
      };
    case "ORDERS_RESET_MESSAGE":
      return {
        ...state,
        successOrders: false,
        errorOrders: false,
      };
    case "ORDERS_FAKE_RESET":
      return {
        ...initialState,
      };
    case "DISCONNECT":
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
