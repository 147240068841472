import { setJWT } from "../../../utils/axios";
const initialState = {
  isLoadingSecurity: false,
  errorSecurity: false,
  successSecurity: false,
  dataSecurity: false,
  modeSecurity: "DEPORTISTA",
  renewSecurity: false,
};
setJWT(localStorage.getItem("4races_jwt_token"));
export const securityReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case "SECURITY_LOADING":
      return {
        ...state,
        isLoadingSecurity: true,
        errorSecurity: false,
        successSecurity: false,
      };
    case "SECURITY_SIGNUP":
      return {
        ...state,
        isLoadingSecurity: false,
        successSecurity: payload.message,
        errorSecurity: false,
      };
    case "SECURITY_LOGIN":
      return {
        ...state,
        isLoadingSecurity: false,
        errorSecurity: false,
        successSecurity: payload.message,
        dataSecurity: payload.data,
        modeSecurity: "DEPORTISTA",
      };
    case "SECURITY_REGISTER_ORGANIZADOR": {
      return {
        ...state,
        isLoadingSecurity: false,
        successSecurity: payload.message,
        errorSecurity: false,
      };
    }
    case "SECURITY_MODE": {
      let temp_mode =
        state.modeSecurity === "ORGANIZADOR" ? "DEPORTISTA" : "ORGANIZADOR";
      return {
        ...state,
        modeSecurity: temp_mode,
      };
    }
    case "SECURITY_RENEW_START": {
      return {
        ...state,
        renewSecurity: true,
      };
    }
    case "SECURITY_RENEW_END": {
      localStorage.setItem("4races_jwt_token", payload.token);
      return {
        ...state,
        isLoadingSecurity: false,
        errorSecurity: false,
        successSecurity: payload.message,
        dataSecurity: payload.data,
        renewSecurity: false,
      };
    }
    case "SECURITY_UPDATE_PROFILE":
      return {
        ...state,
        isLoadingSecurity: false,
        dataSecurity: payload.data,
        successSecurity: payload.message,
        errorSecurity: false,
      };
    case "SECURITY_VALIDATE_TOKEN":
      localStorage.setItem("4races_jwt_token", payload.token);
      return {
        ...state,
        isLoadingSecurity: false,
        errorSecurity: false,
        successSecurity: payload.message,
        dataSecurity: payload.data,
      };
    case "SECURITY_ERROR":
      return {
        ...state,
        isLoadingSecurity: false,
        errorSecurity: payload,
        successSecurity: false,
      };
    case "SECURITY_RESET_MESSAGE":
      return {
        ...state,
        successSecurity: false,
        errorSecurity: false,
      };
    case "SECURITY_FAKE_RESET":
      return {
        ...initialState,
      };
    case "DISCONNECT":
      localStorage.removeItem("4races_jwt_token");
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
