const initialState = {
  isLoadingEvents: false,
  errorEvents: false,
  successEvents: false,
  dataEvents: false,
};
export const eventsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case "EVENTS_LOADING":
      return {
        ...state,
        isLoadingEvents: true,
        errorEvents: false,
        successEvents: false,
        dataEvents: false,
      };
    case "EVENTS_CREATE_EVENT":
      return {
        ...state,
        isLoadingEvents: false,
        successEvents: payload.message,
        errorEvents: false,
      };
    case "EVENTS_FETCH_ALL":
      return {
        ...state,
        isLoadingEvents: false,
        successEvents: payload.message,
        errorEvents: false,
        dataEvents: payload.data,
      };
    case "EVENTS_ERROR":
      return {
        ...state,
        isLoadingEvents: false,
        errorEvents: payload,
        successEvents: false,
      };
    case "EVENTS_RESET_MESSAGE":
      return {
        ...state,
        successEvents: false,
        errorEvents: false,
      };
    case "EVENTS_FAKE_RESET":
      return {
        ...initialState,
      };
    case "DISCONNECT":
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
