const initialState = {
  isLoadingStripe: false,
  errorStripe: false,
  successStripe: false,
  dataStripe: false,
};
export const stripeReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case "STRIPE_LOADING":
      return {
        ...state,
        isLoadingStripe: true,
        errorStripe: false,
        successStripe: false,
      };
    case "STRIPE_ONBOARDING":
      return {
        ...state,
        isLoadingStripe: false,
        successStripe: payload.message,
        errorStripe: false,
        dataStripe: payload.data,
      };
    case "STRIPE_SUCCESS":
      return {
        ...state,
        isLoadingStripe: false,
        successStripe: payload.message,
        errorStripe: false,
      };
    case "STRIPE_ERROR":
      return {
        ...state,
        isLoadingStripe: false,
        errorStripe: payload,
        successStripe: false,
      };
    case "STRIPE_RESET_MESSAGE":
      return {
        ...state,
        successStripe: false,
        errorStripe: false,
      };
    case "STRIPE_FAKE_RESET":
      return {
        ...initialState,
      };
    case "DISCONNECT":
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
