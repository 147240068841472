import "./assets/css/fonts.css";
import "./assets/css/reset.css";
import "./assets/css/global.css";
import ReactDOM from "react-dom/client";
import App from "./App";
import { Provider } from "react-redux";
import { store, persistor } from "./store";
import { PersistGate } from "redux-persist/integration/react";
import { Toaster } from "react-hot-toast";
import "./i18n";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <Toaster
        toastOptions={{
          duration: 5000,
          className: "btn1",
          style: {
            borderRadius: "10px",
            background: "#333",
            fontWeight: "bold",
            color: "#fff",
            fontFamily: "var(--font-family)",
          },
        }}
      />
      <App />
    </PersistGate>
  </Provider>
);
