const initialState = {
  isLoadingBackoffice: false,
  errorBackoffice: false,
  successBackoffice: false,
  dataBackoffice: false,
};
export const backofficeReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case "BACKOFFICE_LOADING":
      return {
        ...state,
        isLoadingBackoffice: true,
        errorBackoffice: false,
        successBackoffice: false,
      };
    case "BACKOFFICE_FETCH_ALL":
      return {
        ...state,
        isLoadingBackoffice: false,
        successBackoffice: payload.message,
        errorBackoffice: false,
        dataBackoffice: payload.data,
      };
    case "BACKOFFICE_SUCCESS":
      return {
        ...state,
        isLoadingBackoffice: false,
        successBackoffice: payload.message,
        errorBackoffice: false,
      };
    case "BACKOFFICE_ERROR":
      return {
        ...state,
        isLoadingBackoffice: false,
        errorBackoffice: payload,
        successBackoffice: false,
      };
    case "BACKOFFICE_RESET_MESSAGE":
      return {
        ...state,
        successBackoffice: false,
        errorBackoffice: false,
      };
    case "BACKOFFICE_FAKE_RESET":
      return {
        ...initialState,
      };
    case "DISCONNECT":
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
