import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

const resources = {
  en: {
    translation: {
      "Login-00-1": "Try again",
      "Login-00-2": "Password recovery",
      "Login-00-3": "Enter the email you use in 4Races.",
      "Login-00-4": "New Password",
      "Login-00-5":
        "Password must be at least 8 characters, at least one digit, at least one lowercase letter, at least one uppercase letter, and at least one non-alphanumeric character such as @$!%*?&.:,#+-",
      "Login-00-6": "Back to login",
      "Login-00-7": "Register",
      "Login-00-8": "Sign up for free",
      "Login-00-9": "It's fast and easy.",
      "Login-00-10": "Are you an athlete or organizer? *",
      "Login-00-11": "Athlete",
      "Login-00-12": "Organizer",
      "Login-00-13": "Complete your profile",
      "Login-00-14": "Step 1 of 2",
      "Login-00-15": "Step 2 of 2",
      "Login-00-16": "What is the name of your company?",
      "Login-00-17": "What is your company's CIF/NIF?",
      "Login-00-18": "Error, Try again",
      "Login-00-18-a": "Try again",

      "Login-00-19":
        "Welcome to 4Races, a verification code has been sent to your email address.",
      "Login-00-20": "Error at login attempt",
      "Login-00-21": "User registered successfully",
      "Login-00-22": "Error registering user",
      "Login-00-23": "Error verifying token",
      "Login-00-24": "Sending company data",
      "Login-00-25": "Company sent successfully",
      "Login-00-26": "Error adding company data",
      "Login-00-27": "User updated successfully",
      "Login-00-28": "Error adding user data",
      "Login-00-29": "Error recovering password",
      "Login-00-30":
        "Your password has been changed successfully, you can now log in",
      "Login-00-31": "Log out",
      "Profile-00-1": "Company CIF:",
      "Profile-00-2": "Sending registration data",
      "Profile-00-3": "Registration Created successfully",
      "Profile-00-4": "Error creating registration",
      "Profile-00-5": "Searching Nfts",
      "Profile-00-6": "Registrations found successfully",
      "Profile-00-7": "You don't have nfts",
      "Profile-00-8": "Error searching nfts",
      "Profile-00-9": "Buying registration",
      "Home-0": "No Events Available",
      "Home-1": "Where your goals come to life",
      "Home-2": "Plan your season with 4Races",
      "Home-3": "Create your Calendar",
      "Home-4": "All",
      "Home-5": "Running",
      "Home-6": "Triathlon",
      "Home-7": "Cycling",
      "Home-8": "Swimming",
      "Home-9": "View category",
      "Home-10": "Beat the heat",
      "Home-11": "Cool and colorful running gear",
      "Home-12":
        "Breathable, quick-drying, and sweat-wicking fabrics for your summer runs",
      "Home-13": "Coming soon",
      "Home-14": "Sea or road",
      "Home-15":
        "Breathable, quick-drying, and sweat-wicking fabrics for your summer runs.",
      "Home-16": "Don't miss anything",
      "Home-17": "Unleash the champion within you:",
      "Home-18": "Exclusive options tailored for runners!",
      "Home-19":
        "Immerse yourself in our innovative platform for runners. Get exclusive options in your races, runner souvenirs, and personalized badges. Fuel your passion and reach new goals.",
      "Home-20": "View upcoming event",
      "Home-21": "Transforming sports events with technology.",
      "Home-22": "Contact",
      "Home-23": "Legal Notice",
      "Home-24": "Terms and Conditions",
      "Home-25": "Data Protection",
      "Home-26": "Follow us on:",
      "Calendario-27": "Create your Calendar",
      "Calendario-28": "Select the category",
      "Calendario-29": "Select the subcategory",
      "Calendario-30": "Half Marathon",
      "Calendario-31": "Marathon",
      "Calendario-32": "Select the date",
      "Calendario-33": "Select your location or territory",
      "Calendario-34": "Looking for events",
      "Login-35": "Loading user data",
      "home-36": "Search events",
      "Login-37": "Welcome",
      "Login-38": "Email",
      "Login-39": "Please use your email to log in",
      "Login-40": "Password",
      "Login-41": "Remember password",
      "Login-42": "Forgot password",
      "Login-43": "Log in",
      "Login-44": "Are you new?",
      "Login-45": "Sign up for free",
      "Login-46": "Authentication Token",
      "Login-47": "Validate authentication",
      "Miperfil-48": "My account",
      "Miperfil-49": "My profile",
      "Miperfil-50": "Athlete Mode",
      "Miperfil-51": "Organizer Mode",
      "Miperfil-52": "Log out",
      "Profile-53": "Your account",
      "Profile-54": "Overview",
      "Profile-55": "Purchased Registrations",
      "Profile-56": "Your data",
      "Profile-57": "Data and privacy",
      "Profile-58": "Help and contact",
      "Profile-59": "Request to delete data",
      "Profile-60": "License number",
      "Profile-61": "Date of birth",
      "Profile-62": "Edit",
      "Profile-63": "Next events",
      "Profile-64": "Need to buy a registration",
      "Profile-65": "You don't have NFTS",
      "Profile-66": "Personal Information",
      "Profile-67": "Name",
      "Profile-68": "Last name",
      "Profile-69": "Email",
      "Profile-70": "Description",
      "Profile-71": "License number",
      "Profile-72": "Phone number",
      "Profile-73": "Document type",
      "Profile-74": "Document number",
      "Profile-75": "Gender",
      "Profile-76": "Postal address",
      "Profile-77": "Shirt size",
      "Profile-78": "Club",
      "Profile-79": "Profile picture",
      "Profile-80":
        "Must be a .jpg, .gif, or .png file, maximum 10 MB, and at least 400 by 400 pixels.",
      "Profile-81": "Select file",
      "Profile-82": "Save changes",
      "Profile-83": "User data loaded successfully",
      "Profile-84": "Delete Account",
      "Profile-85": "Are you sure you want to delete your account?",
      "Profile-86": "Events found successfully",
      "Profile-87": "Log in",
      "Login-88": "Are you new?",
      "Login-89": "Sign up for free",
      "Login-90": "Sign up",
      "Login-91": "It's fast and easy",
      "Login-92": "Password",
      "Login-93": "Are you an athlete or organizer?",
      "Login-94": "Select",
      "Login-95": "Already have an account?",
      "Login-96": "Welcome",
      "Login-97": "Please use your email to log in",
      "Login-98": "Remember password",
      "Login-99": "Forgot password",
      "Login-100": "Sending identity data",
      "Login-101": "Authentication token",
      "Login-102": "Validate authentication",
      "Login-103": "Session closed successfully",
      "Profile-104": "My events",
      "Profile-105": "Registrations sold",
      "Profile-106": "Create event",
      "Profile-107": "Configure stripe account",
      "Profile-108": "Admin panel",
      "Profile-109": "User list",
      "Profile-110": "Company CIF",
      "Profile-111": "Company name",
      "Profile-112": "Update",
      "Profile-113": "Company",
      "Profile-114": "Role",
      "Profile-115": "User",
      "Profile-116": "This user is validated as an Organizer",
      "Profile-117": "General information",
      "Event-118": "Event location",
      "Event-119": "Event date",
      "Event-120": "Indicate the distance of the route",
      "Event-121": "Select the event mode",
      "Event-122": "Select the event subcategory",
      "Event-123": "Start date of ticket sales",
      "Event-124": "End date of ticket sales",
      "Event-125": "Bib assignment date",
      "Event-126": "Description",
      "Event-127": "Images and multimedia",
      "Event-128": "Event profile picture",
      "Event-129": "Banner image",
      "Event-130": "Save and continue",
      "inscription-131": "List your events",
      "inscription-132": "Create registration",
      "inscription-133": "Create a registration",
      "inscription-134": "Quantity",
      "inscription-135": "Sale expiration date",
      "inscription-136": "Allow resale",
      "inscription-137": "License",
      "inscription-138": "License cost",
      "inscription-139": "Additional",
      "inscription-140": "Questions",
      "inscription-141": "Add new additional",
      "inscription-142": "Add question",
      "inscription-143": "Discount",
      "inscription-144": "Add new discount",
      "inscription-145": "Code",
      "inscription-146": "Percentage",
      "inscription-147": "Remaining quantity",
      "inscription-148": "Question",
      "inscription-149": "Enter a question",
      "inscription-150": "Delete question",
      "inscription-151": "Concept",
      "inscription-152": "Value",
      "inscription-153": "Delete",
      "inscription-154": "Yes",
      "inscription-155": "No",
      "Profile-156": "QR Validation",
      "Profile-157": "Purchase Date",
      "Profile-158": "Redeem via QR",
      "Profile-159": "No entries found",
      "Event-160": "Sign up now",
      "Event-161": "Distance",
      "Event-162": "Days",
      "Event-163": "Minutes",
      "Event-164": "Seconds",
      "Event-165": "Hours",
      "Event-166": "Searching for event",
      "Event-167": "Profile",
      "Event-168": "Purchase registration",
      "Event-169": "Events found successfully",
      "Event-170": "Location",
      "Event-171": "Registration",
      "Event /detail-172": "Register now",
      "Event /detail-173": "What does the bib include?",
      "Event /detail-174":
        "The services included with the bib are the responsibility of each of the organizations, which should provide information on the event page or their official event website.",
      "Event /detail-175":
        "What happens if I can't participate in the event after all?",
      "Event /detail-176":
        "The platform is set up to facilitate secure transfers between athletes. However, it will be up to each Organizer to decide whether to allow resale of bibs.",
      "Event /detail-177": "Can I change my bib?",
      "Event /detail-178":
        "No, once a certain point is reached, the entry is assigned to a bib number. Before this happens, if the organizer allows it, you may be able to sell your entry.",
      "Event /detail-179": "Can I customize it?",
      "Event /detail-180":
        "The entry comes with the name, event, and all personalized information to redeem your entry for a QR code to access the race.",
      "Event/payment-181": "Detail",
      "Event/payment-182": "Payment",
      "Event/payment-183": "End of Sale",
      "Event/payment-184": "Assignment",
      "Event/payment-185": "Event Date",
      "Event/payment-186": "Email Address",
      "Event/payment-187": "Article",
      "Event/payment-188": "Do you have a discount code?",
      "Event/payment-189": "Total to pay",
      "Event/payment-190": "Pay",
      "Event/payment-191": "Billing address",
      "Event/payment-192": "Change",
      "Event/payment-193": "Payment method",
      "Event/payment-194": "Subtotal",
      "Event/payment-195": "Purchasing registration",
      "Event/payment-196": "Sale has ended or purchase was not made",
      "Event-00-0": "Back to home page",
      "Event-00-1": "You are the Organizer of this event",
      "Event-00-2": "Category:",
      "Event-00-3": "Subcategory:",
      "Event-00-4": "Distance:",
      "Event-00-5": "Purchase not enabled",
      "Event-00-6": "Write your answer",
      "Event-00-7": "Assign",
      "Event-00-8": "Bib",
      "Event-00-9": "Confirm",
      "Event-00-10": "Start of Sale",
      "Event-00-11": "End of Sale",
      "Extra-1": "Price used",
      "Extra-2": "Stripe not configured",
      "Extra-3": "Sell",
      "Extra-4": "Inscription purchased successfully",
      "Extra-5": "You need to redeem your registrations",
      "Extra-6": "Your Orders",
      "Extra-7": "The size of the image cannot be greater than 1MB",
      "Extra-8": "The format of the file must be PNG, JPG or JPEG",
      "Extra-9": "Your Created Events",
      "Extra-10": "Search event or race",
      "Extra-11": "Add image",
      "Extra-12": "Success ",
      "Extra-13": "Thank you for your purchase!",
      "Extra-14": "Check",
      "Extra-15": "Profile",
      "Extra-16": "Sales",
      "Extra-17": "Request or delete data",
      "Extra-18": "Events",
      "Extra-19": "My Sold Inscriptions",
      "Extra-20": "Validate QR",
      "Extra-21": "Support",
      "Extra-22":
        "Are you experiencing difficulties when buying the registration",
      "Extra-23":
        "If you are having problems completing the purchase of your registration, it is important to remember that, in the event that it is your first transaction on our platform, it is essential that all the fields of your profile are duly completed to to be able to successfully proceed with the purchase of the bib. Ensuring that all your profile details are complete helps us ensure a smooth purchasing experience and facilitate any process related to your participation in our event. If you require additional assistance or have any questions about completing your profile, feel free to contact us at mpeco@4races.com. We are here to help you every step of the process.",
      "Extra-24": "Do you have questions about how to redeem your ticket? ",
      "Extra-25":
        "If you have inquiries about how to redeem your ticket, we recommend following these steps to ensure you're ready for race day: Before the event day, make sure to access the registrations section of your account and redeem the QR code associated with your ticket. This will take you to the tickets section, where you can find your QR code ready for use. During bib pickup, the event organizer will scan the QR code from your mobile device to validate your ticket. Make sure to have your QR code accessible and ready to be scanned to facilitate the registration process. If you have any additional questions about the ticket redemption process or need further assistance, don't hesitate to contact our customer support team. We're here to ensure that your event experience is as smooth and satisfactory as possible.",
    },
  },
  es: {
    translation: {
      "Login-00-1": "Intentalo de nuevo",
      "Login-00-2": "Recuperación de contraseña",
      "Login-00-3": "Introduce el email que utilizas en 4Races.",
      "Login-00-4": "Nueva Contraseña",
      "Login-00-5":
        "La contraseña debe tener al menos 8 caracteres, al menos un dígito, al menos una minúscula, al menos una mayúscula y al menos un caracter no alfanumérico como @$!%*?&.:,#+-",
      "Login-00-6": "Volver a iniciar sesión",
      "Login-00-7": "Registrar",
      "Login-00-8": "Regístrate",
      "Login-00-9": "Es rápido y fácil.",
      "Login-00-10": "¿Eres deportista o organizador? *",
      "Login-00-11": "Deportista",
      "Login-00-12": "Organizador",
      "Login-00-13": "Completa tu perfil",
      "Login-00-14": "Paso 1 de 2",
      "Login-00-15": "Paso 2 de 2",
      "Login-00-16": "¿Cuál es el nombre de tu empresa?",
      "Login-00-17": "¿Cuál es tu CIF/NIF de empresa?",
      "Login-00-18": "Error, Vuelve a intentarlo",
      "Login-00-18-a": " Vuelve a intentarlo",
      "Login-00-19":
        "Bienvenido a 4Races, se ha enviado un código de verificación a su correo electrónico",
      "Login-00-20": "Error, al inciar sesión",
      "Login-00-21": "Usuario registrado correctamente",
      "Login-00-22": "Error al registrar usuario",
      "Login-00-23": "Error al verificar token",
      "Login-00-24": "Enviando datos de empresa",
      "Login-00-25": "Empresa enviada correctamente",
      "Login-00-26": "Error al agregar datos de empresa",
      "Login-00-27": "Usuario actualizado correctamente",
      "Login-00-28": "Error al agregar datos de usuario",
      "Login-00-29": "Error al recuperar contraseña",
      "Login-00-30":
        "Se ha cambiado su contraseña correctamente, ya puede iniciar sesión",
      "Login-00-31": "Cerrar sesión",
      "Profile-00-1": "CIF de empresa:",
      "Profile-00-2": "Enviando datos del inscripción",
      "Profile-00-3": "Inscripción Creado con exito",
      "Profile-00-4": "Error al crear inscripción",
      "Profile-00-5": "Buscando Nfts",
      "Profile-00-6": "Inscripciones encontradas con exito",
      "Profile-00-7": "No tienes nfts",
      "Profile-00-8": "Error al buscar nfts",
      "Profile-00-9": "Comprando inscripción",
      "Home-0": "No hay eventos disponibles",
      "Home-1": "Donde tus metas cobran vida",
      "Home-2": "Planifica tu temporada con 4Races",
      "Home-3": "Crea tu Calendario",
      "Home-4": "Todos",
      "Home-5": "Running",
      "Home-6": "Triathlon",
      "Home-7": "Ciclismo",
      "Home-8": "Natación",
      "Home-9": "Ver categoria",
      "Home-10": "Combate el calor",
      "Home-11": "Equipamiento de running fresco y colorido",
      "Home-12":
        "Tejidos transpirables, de secado rápido y que evacuan el sudor para tus carreras de verano",
      "Home-13": "Próximamente",
      "Home-14": "Mar o carretera",
      "Home-15":
        "Tejidos transpirables, de secado rápido y que evacuan el sudor para tus carreras de verano.",
      "Home-16": "No te pierdas nada",
      "Home-17": "Libera al campeón que llevas dentro:",
      "Home-18": "¡Opciones exclusivas a medida de los corredores!",
      "Home-19":
        "Sumérgete en nuestra innovadora plataforma para corredores. Consigue opciones exclusivas en tus carreras, recuerdos de corredores e insignias personalizadas. Alimenta tu pasión y alcanza nuevas metas.",
      "Home-20": "Ver próximo evento",
      "Home-21": "Transformando eventos deportivos con tecnología.",
      "Home-22": "Contacto",
      "Home-23": "Aviso Legal",
      "Home-24": "Términos y condiciones",
      "Home-25": "Protección de datos",
      "Home-26": "Siguenos en: ",
      "Calendario-27": "Crea tu Calendario",
      "Calendario-28": "Selecciona la modalidad",
      "Calendario-29": "Selecciona la subcategoría",
      "Calendario-30": "Media Maraton",
      "Calendario-31": "Maraton",
      "Calendario-32": "Selecciona la fecha",
      "Calendario-33": "Selecciona tu ubicación o territorio",
      "Calendario-34": "Buscando eventos",
      "Login-35": "Cargando datos usuario",
      "home-36": "Buscar eventos",
      "Login-37": "Bienvenido",
      "Login-38": "correo electronico",
      "Login-39": "Por favor, usa tu correo electronico para iniciar sesión",
      "Login-40": "Contraseña",
      "Login-41": "Recordar contraseña",
      "Login-42": "Contraseña olvidada",
      "Login-43": "Iniciar sesision",
      "Login-44": "¿Eres nuevo?",
      "Login-45": "Registrate Gratis",
      "Login-46": "Token Autentificación",
      "Login-47": "Validar autenticación",
      "Miperfil-48": "Mi cuenta",
      "Miperfil-49": "Mi perfil ",
      "Miperfil-50": "Modo deportista",
      "Miperfil-51": "Modo Organizador",
      "Miperfil-52": "Cerrar sesion",
      "Profile-53": "Tu cuenta",
      "Profile-54": "Vista General",
      "Profile-55": "Inscripciones Compradas",
      "Profile-56": "Tus datos",
      "Profile-57": "Datos y privacidad",
      "Profile-58": "Ayuda y contacto",
      "Profile-59": "Solicitar eliminar datos",
      "Profile-60": "Numero de liciencia",
      "Profile-61": "Fecha de nacimiento",
      "Profile-62": "editar",
      "Profile-63": "Siguientes eventos",
      "Profile-64": "Necesitas comprar una inscripción",
      "Profile-65": "NO tienes NFTS",
      "Profile-66": "Información Personal",
      "Profile-67": "Nombre",
      "Profile-68": "Apellido",
      "Profile-69": "correo electronico",
      "Profile-70": "Descripción",
      "Profile-71": "número de licencia",
      "Profile-72": "número de telefono",
      "Profile-73": "Tipo de documento",
      "Profile-74": "Numero de documento",
      "Profile-75": "Genero",
      "Profile-76": "Dirrección Postal",
      "Profile-77": "Talla de camisa",
      "Profile-78": "Club",
      "Profile-79": "Foto de perfil",
      "Profile-80":
        "Debe ser un archivo .jpg, .gif o .png de 10 MB como máximo y de al menos 400 por 400 píxeles.",
      "Profile-81": "Seleccionar archivo",
      "Profile-82": "Guardar cambios",
      "Profile-83": "Datos de usuario cargados correctamente",
      "Profile-84": "Eliminar Cuenta",
      "Profile-85": "¿Estas seguro que deseas eliminar tu cuenta?",
      "Profile-86": "Eventos encontrados con éxito",
      "Profile-87": "Iniciar sesión",
      "Login-88": "¿Eres Nuevo?",
      "Login-89": "Registrate gratis",
      "Login-90": "Registrate",
      "Login-91": "Es rapido y facil",
      "Login-92": "Contraseña",
      "Login-93": "¿eres deportista o organiador?",
      "Login-94": "Selecciona",
      "Login-95": "¿Ya tienes cuenta?",
      "Login-96": "Bienvenido",
      "Login-97": "Por favor, usa tu correo electronico para iniciar sesión",
      "Login-98": "Recordar contraseña",
      "Login-99": "Contraseña olvidada",
      "Login-100": "Enviando datos de identidad",
      "Login-101": "Token de autenticación",
      "Login-102": "Validar autenticación",
      "Login-103": "Sesion cerrada correctamente",
      "Profile-104": "Mis eventos",
      "Profile-105": "Inscripciones vendidas",
      "Profile-106": "Crear evento",
      "Profile-107": "Configurar cuenta stripe",
      "Profile-108": "Panel de administrador",
      "Profile-109": "Lista de usuarios",
      "Profile-110": "Cif de la empresa",
      "Profile-111": "Nombre de la empresa",
      "Profile-112": "Actualizar",
      "Profile-113": "Empresa",
      "Profile-114": "Rol",
      "Profile-115": "Usuario",
      "Profile-116": "Este usuario está validado como Organizador",
      "Profile-117": "Información general",
      "Event-118": "Lugar del evento",
      "Event-119": "Fecha del evento",
      "Event-120": "Indica la distancia del recorrido",
      "Event-121": "Selecciona la modalidad del evento ",
      "Event-122": "Selecciona la subcategoria del evento",
      "Event-123": "Fecha de inicio de venta de entradas",
      "Event-124": "Fecha de fin de venta de entradas",
      "Event-125": "Fecha de asignación de dorsales ",
      "Event-126": "Descripcion",
      "Event-127": "Imágenes y multimedia",
      "Event-128": "Foto perfil del evento",
      "Event-129": "Imagen banner",
      "Event-130": "Guardar y continuar",
      "inscription-131": "Lista tus eventos",
      "inscription-132": "Crear inscripción",
      "inscription-133": "Crear una inscripción",
      "inscription-134": "Cantidad",
      "inscription-135": "Fecha de caducidad de venta",
      "inscription-136": "Permites la re-venta",
      "inscription-137": "Licencia",
      "inscription-138": "Coste de la licencia",
      "inscription-139": "Adicionales",
      "inscription-140": "Preguntas",
      "inscription-141": "Agregar Nuevo adicional",
      "inscription-142": "AgregarPreguntar",
      "inscription-143": "Descuento",
      "inscription-144": "Agregar nuevo descuento",
      "inscription-145": "Codigo",
      "inscription-146": "porcentaje",
      "inscription-147": "Cantidad de veces restantes",
      "inscription-148": "Pregunta",
      "inscription-149": "Ingresa una pregunta",
      "inscription-150": "Eliminar Pregunta",
      "inscription-151": "Cocepto ",
      "inscription-152": "Valor",
      "inscription-153": "Eliminar",
      "inscription-154": "Si",
      "inscription-155": "No",
      "Profile-156": "Validar QR",
      "Profile-157": "Fecha Compra",
      "Profile-158": "Canjear por QR",
      "Profile-159": "No se encontraron entradas",
      "Event-160": "Inscríbete ahora",
      "Event-161": "Distancia",
      "Event-162": "Dias",
      "Event-163": "Minutos",
      "Event-164": "Segundos",
      "Event-165": "Horas",
      "Event-166": "Buscando evento",
      "Event-167": "Inicio",
      "Event-168": "Comprar inscripcion",
      "Event-169": "Eventos encontrado con éxito",
      "Event-170": "Lugar",
      "Event-171": "Inscripción",
      "Event /detail-172": "Inscribete ya",
      "Event /detail-173": "¿Qué incluye el dorsal?",
      "Event /detail-174":
        "Los servicios que incluya el dorsal serán responsabilidad de cada una de las organizaciones , que deberá dar la información en la página del evento o en su website oficial del evento.",
      "Event /detail-175":
        "¿Qué ocurre si finalmente no puedo participar en el evento?",
      "Event /detail-176":
        "La plataforma está preparada para poder hacer traspasos seguros entre atletas. No obstante, será cada Organizador que decida si permite o no la reventa de los dorsales.",
      "Event /detail-177": "¿Puedo cambiar de dorsal?",
      "Event /detail-178":
        "No, a partir de cierto momento la entrada se asigna a un número de dorsal. Antes de que esto ocurra, si el organizador lo permite, es posible que puedas poner a la venta tu entrada.",
      "Event /detail-179": "¿Puedo personalizarlo?",
      "Event /detail-180":
        "La entrada viene con el nombre, evento y todo personalizado para poder canjear tu entrada por un QR para acceder a la carrera.",
      "Event/payment-181": "Detalle",
      "Event/payment-182": "Pago",
      "Event/payment-183": "Fin de Venta",
      "Event/payment-184": "Asignación",
      "Event/payment-185": "Fecha de evento",
      "Event/payment-186": "Dirección de email",
      "Event/payment-187": "Artculo",
      "Event/payment-188": "Tienes un codigo de descuento?",
      "Event/payment-189": "Total a pagar",
      "Event/payment-190": "Pagar",
      "Event/payment-191": "Dirección de facturación",
      "Event/payment-192": "Cambiar",
      "Event/payment-193": "Tipo de pago",
      "Event/payment-194": "Subtotal",
      "Event/payment-195": "Comprando inscripción",
      "Event/payment-196": "Ha finalizado o no ha comprado la venta",
      "Event-00-0": "Volver a la pagina de inicio",
      "Event-00-1": "Eres Organizador de este evento",
      "Event-00-2": "Modalidad:",
      "Event-00-3": "Subcategoria:",
      "Event-00-4": "Distancia:",
      "Event-00-5": "No habilitada la compra",
      "Event-00-6": "Escribe tu respuesta",
      "Event-00-7": "Asignar",
      "Event-00-8": "Dorsales",
      "Event-00-9": "confirmar",
      "Event-00-10": "Incio Venta",
      "Event-00-11": "Fin Venta",
      "Extra-1": "Precio",
      "Extra-2": "Stripe aun no vinculado",
      "Extra-3": "Vender",
      "Extra-4": "Inscripción Comprada con exito",
      "Extra-5": "Necesitas canejar tus inscripciones",
      "Extra-6": "Tus Pedidos",
      "Extra-7": "El tamaño de la imagen no puede ser mayor a 1MB",
      "Extra-8": "El formato del archivo debe ser PNG, JPG o JPEG",
      "Extra-9": "Tus Eventos Creados",
      "Extra-10": "Busca la carrera o evento",
      "Extra-11": "Añadir imagen",
      "Extra-12": "Compra Exitosa",
      "Extra-13": "¡Gracias por tu compra!",
      "Extra-14": "Revisar",
      "Extra-15": "Mi perfil",
      "Extra-16": "Entradas",
      "Extra-17": "Solicitar o eliminar datos",
      "Extra-18": "Eventos",
      "Extra-19": "Mis Inscripciones Vendidas",
      "Extra-20": "Valdiar QR",
      "Extra-21": "Soporte",
      "Extra-22":
        "¿Estás experimentando dificultades al comprar la inscripción?",
      "Extra-23":
        "Si estás teniendo problemas para completar la compra de tu inscripción, es importante recordar que, en el caso de que sea tu primera transacción en nuestra plataforma, es imprescindible que todos los campos de tu perfil estén debidamente cumplimentados para poder proceder con éxito a la adquisición del dorsal. Asegurarnos de que todos los detalles de tu perfil estén completos nos ayuda a garantizar una experiencia de compra sin contratiempos y a facilitar cualquier proceso relacionado con tu participación en nuestro evento. Si necesitas asistencia adicional o tienes alguna pregunta sobre cómo completar tu perfil, no dudes en contactarnos en mpeco@4races.com. Estamos aquí para ayudarte en cada paso del proceso.",
      "Extra-24": "¿Tienes dudas sobre cómo canjear tu entrada?",
      "Extra-25":
        "Si tienes preguntas sobre cómo canjear tu entrada, te recomendamos seguir estos pasos para asegurarte de que estés listo para el día de la carrera: Antes del día del evento, asegúrate de acceder a la sección de inscripciones de tu cuenta y canjear el código QR asociado a tu entrada. Esto te llevará al apartado de entradas, donde podrás encontrar tu código QR listo para su uso. Durante la recogida del dorsal, el organizador del evento escaneará el código QR desde tu dispositivo móvil para validar tu entrada. Asegúrate de tener tu código QR accesible y listo para ser escaneado para facilitar el proceso de registro. Si tienes alguna pregunta adicional sobre el proceso de canje de entradas o necesitas ayuda adicional, no dudes en ponerte en contacto con nuestro equipo de atención al cliente. Estamos aquí para asegurarnos de que tu experiencia en el evento sea lo más fluida y satisfactoria posible.",
    },
  },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    resources,
    lng: localStorage.getItem("i18nextLng") || "es",
    fallbackLng: "es",
  });

export default i18n;
