import axios from "axios";
const axiosParams = {
  baseURL: "https://4races.rodrigoerazo.com",
  headers: { "cache-control": "no-cache", "Content-Type": "application/json" },
};
const axiosApiParams = {
  baseURL: "https://www.runnink.com/api/type/index.php/events/list/",
  headers: { "Content-Type": "application/json" },
};
// const axiosApiParamsTester = {
//   baseURL: "https://pasarela.cryptopocket.io/",
//   headers: {
//     "Content-Type": "application/json",
//     auth: "PK-65671AB2A68A49.82283599",
//   },
// };
// https://www.clubrunning.es/cartel/Ultra-Sierra-Nevada-Extrema-2024.jpg
// https://www.clubrunning.es/carrera.php?id=62973
const publicAxios = axios.create(axiosParams);
const privateAxios = axios.create(axiosParams);
const club_api_Axios = axios.create(axiosApiParams);
// const tester_api_Axios = axios.create(axiosApiParamsTester);
export const setJWT = (jwt) => {
  privateAxios.defaults.headers.common["Authorization"] = `Bearer ${jwt}`;
};

export const codeInterceptor = (handler) => {
  privateAxios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response) {
        switch (error.response.status) {
          case 401:
            handler();
            break;
          default:
            console.log(error);
        }
      } else {
        console.log(error);
      }
      return Promise.reject(error);
    }
  ); // end use
};

export const naxios = publicAxios;
export const paxios = privateAxios;
export const alt_axios = club_api_Axios;
// export const test_axios = tester_api_Axios;
