import { Suspense, lazy } from "react";
import { Routes, Route, Navigate, BrowserRouter } from "react-router-dom";
import { codeInterceptor } from "./utils/axios";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import Spinner from "./components/Spinner";
const Home = lazy(() => import("./pages/Home"));
const Marketplace = lazy(() => import("./pages/Marketplace"));
const CreateEvent = lazy(() => import("./pages/CreateEvent"));
const EditEvent = lazy(() => import("./pages/EditEvent"));
const CreateInscription = lazy(() => import("./pages/CreateInscription"));
const Profile = lazy(() => import("./pages/Profile"));
const Event = lazy(() => import("./pages/Event"));
const EventDetail = lazy(() => import("./pages/EventDetail"));
const Payment = lazy(() => import("./pages/Payment"));
const Login = lazy(() => import("./pages/Login"));
const ValidateTokenLogin = lazy(() => import("./pages/ValidateTokenLogin"));
const Register = lazy(() => import("./pages/Register"));
const GetStarted = lazy(() => import("./pages/GetStarted"));
const Recover = lazy(() => import("./pages/Recover"));
////////////
const SuccessPayment = lazy(() => import("./pages/SuccessPayment"));
const FailPayment = lazy(() => import("./pages/FailPayment"));

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    codeInterceptor(() => {
      dispatch({
        type: "DISCONNECT",
      });
    });
  }, [dispatch]);
  return (
    <Suspense
      fallback={
        <Spinner full>
          <h6>Runners</h6>
        </Spinner>
      }
    >
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/marketplace" element={<Marketplace />} />
          <Route path="/profile">
            <Route index element={<Profile />} />
            <Route path="/profile/:tab" element={<Profile />} />
          </Route>
          <Route path="/event">
            <Route index element={<CreateEvent />} />
            <Route path="/event/:id">
              <Route index element={<Event />} />
              <Route path="/event/:id/edit" element={<EditEvent />} />
              <Route path="/event/:id/detail" element={<EventDetail />} />
              <Route path="/event/:id/payment" element={<Payment />} />
            </Route>
          </Route>
          <Route path="/inscription">
            <Route index element={<CreateInscription />} />
            <Route
              path="/inscription/:eventoId"
              element={<CreateInscription />}
            />
          </Route>
          <Route path="/login" element={<Login />} />
          <Route path="/validate_token" element={<ValidateTokenLogin />} />
          <Route path="/register" element={<Register />} />
          <Route path="/get-started" element={<GetStarted />} />
          <Route path="/forgot-password" element={<Recover />} />
          {/* // */}
          <Route path="/success">
            <Route index element={<Navigate to={"/"} />} />
            <Route path="/success/:order" element={<SuccessPayment />} />
          </Route>
          <Route path="/cancel">
            <Route index element={<Navigate to={"/"} />} />
            <Route path="/cancel/:order" element={<FailPayment />} />
          </Route>

          {/* // */}
          <Route path="*" element={<Navigate to={"/"} />} />
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
};

export default App;
