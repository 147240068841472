const initialState = {
  isLoadingInscriptions: false,
  errorInscriptions: false,
  successInscriptions: false,
  dataInscriptions: false,
};
export const inscriptionsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case "INSCRIPTIONS_LOADING":
      return {
        ...state,
        isLoadingInscriptions: true,
        errorInscriptions: false,
        successInscriptions: false,
        dataInscriptions: false,
      };
    case "INSCRIPTIONS_CREATE":
      return {
        ...state,
        isLoadingInscriptions: false,
        successInscriptions: payload.message,
        errorInscriptions: false,
      };
    case "INSCRIPTIONS_FETCH_ALL":
      return {
        ...state,
        isLoadingInscriptions: false,
        successInscriptions: payload.message,
        errorInscriptions: false,
        dataInscriptions: payload.data,
      };

    case "INSCRIPTIONS_ERROR":
      return {
        ...state,
        isLoadingInscriptions: false,
        errorInscriptions: payload,
        successInscriptions: false,
      };
    case "INSCRIPTIONS_RESET_MESSAGE":
      return {
        ...state,
        successInscriptions: false,
        errorInscriptions: false,
      };
    case "INSCRIPTIONS_FAKE_RESET":
      return {
        ...initialState,
      };
    case "DISCONNECT":
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
